// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-abgesagt-jsx": () => import("./../../../src/pages/abgesagt.jsx" /* webpackChunkName: "component---src-pages-abgesagt-jsx" */),
  "component---src-pages-datenschutzbestimmungen-jsx": () => import("./../../../src/pages/datenschutzbestimmungen.jsx" /* webpackChunkName: "component---src-pages-datenschutzbestimmungen-jsx" */),
  "component---src-pages-erfolgreich-jsx": () => import("./../../../src/pages/erfolgreich.jsx" /* webpackChunkName: "component---src-pages-erfolgreich-jsx" */),
  "component---src-pages-impressum-jsx": () => import("./../../../src/pages/impressum.jsx" /* webpackChunkName: "component---src-pages-impressum-jsx" */),
  "component---src-templates-blog-jsx": () => import("./../../../src/templates/blog.jsx" /* webpackChunkName: "component---src-templates-blog-jsx" */),
  "component---src-templates-dalia-pugatsch-jsx": () => import("./../../../src/templates/dalia-pugatsch.jsx" /* webpackChunkName: "component---src-templates-dalia-pugatsch-jsx" */),
  "component---src-templates-events-jsx": () => import("./../../../src/templates/events.jsx" /* webpackChunkName: "component---src-templates-events-jsx" */),
  "component---src-templates-index-jsx": () => import("./../../../src/templates/index.jsx" /* webpackChunkName: "component---src-templates-index-jsx" */),
  "component---src-templates-kontakt-jsx": () => import("./../../../src/templates/kontakt.jsx" /* webpackChunkName: "component---src-templates-kontakt-jsx" */),
  "component---src-templates-kunst-revolution-jsx": () => import("./../../../src/templates/kunst-revolution.jsx" /* webpackChunkName: "component---src-templates-kunst-revolution-jsx" */),
  "component---src-templates-kunst-schule-jsx": () => import("./../../../src/templates/kunst-schule.jsx" /* webpackChunkName: "component---src-templates-kunst-schule-jsx" */),
  "component---src-templates-post-jsx": () => import("./../../../src/templates/post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */),
  "component---src-templates-veranstaltungen-jsx": () => import("./../../../src/templates/veranstaltungen.jsx" /* webpackChunkName: "component---src-templates-veranstaltungen-jsx" */)
}

